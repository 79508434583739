<script setup>
    import Menu from "@/App/Layout/Menu/js/Menu.js";
    import InputField from "@/Components/Inputs/InputField.vue";
    import HighlightText from "@/Components/Other/HighlightText.vue";
    import {reactive, ref, watch} from 'vue';
    import NewSelect from "@/Components/Inputs/NewSelect.vue";

    const props = defineProps({
       menu: Object,
       json: String,
    });

    const menu = reactive(props.menu ? props.menu : Menu.createInstance(JSON.parse(props.json)));

    const search = ref(null);
    const basePath = window.basePath;

    const developersOptions = window.developers.map(item => {
        return {
            value: item,
            text: item,
        };
    });
    const filterDevelopers = ref([]);

    const responsiblePersonOptions = window.responsiblePersons.map(item => {
        return {
            value: item,
            text: item,
        };
    });
    const filterResponsiblePersons = ref([]);

    const departmentsOptions = window.departments.map(item => {
        return {
            value: item,
            text: item,
        };
    });
    const filterDepartments = ref([]);

    const filterMenu = () => {
      const q = search.value?.toLowerCase();
      menu.menuItems.forEach((item) => {
          let isSomeSubItemVisible = false;
          item.menuItems.forEach((subItem) => {
              let isSomeSubSubItemVisible = false;
              subItem.menuItems.forEach((subSubItem) => {
                  if(q) {
                      if (subSubItem.name.toLowerCase().includes(q) || subSubItem.description?.toLowerCase().includes(q)) {
                          subSubItem.visible = true;
                      } else {
                          subSubItem.visible = false;
                      }
                  }else{
                      subSubItem.visible = true;
                  }

                  if(subSubItem.visible && (filterDevelopers.value.length || filterResponsiblePersons.value.length || filterDepartments.value.length)){
                      subSubItem.visible = filterDevelopers.value.some(developer => subSubItem.developers.includes(developer.value)) || filterResponsiblePersons.value.some(person => subSubItem.responsiblePersons.includes(person.value)) || filterDepartments.value.some(department => subSubItem.tags.includes(department.value));
                  }

                  if(subSubItem.visible){
                      isSomeSubSubItemVisible = true;
                  }
              });

              if(isSomeSubSubItemVisible) {
                  isSomeSubItemVisible = true;
                  subItem.visible = true;
                  return;
              }

              if(q) {
                  if (subItem.name.toLowerCase().includes(q) || subItem.description?.toLowerCase().includes(q)) {
                      subItem.visible = true;
                  } else {
                      subItem.visible = false;
                  }
              }else{
                  subItem.visible = true;
              }

              if(subItem.visible && (filterDevelopers.value.length || filterResponsiblePersons.value.length || filterDepartments.value.length)){
                  subItem.visible = filterDevelopers.value.some(developer => subItem.developers.includes(developer.value)) || filterResponsiblePersons.value.some(person => subItem.responsiblePersons.includes(person.value)) || filterDepartments.value.some(department => subItem.tags.includes(department.value));
              }

              if(subItem.visible){
                  isSomeSubItemVisible = true;
              }
          });

          if(isSomeSubItemVisible) {
              item.visible = true;
              return;
          }

          if(q) {
              if (item.name.toLowerCase().includes(q) || item.description?.toLowerCase().includes(q)) {
                  item.visible = true;
              } else {
                  item.visible = false;
              }
          }else{
              item.visible = true;
          }

          if(item.visible && (filterDevelopers.value.length || filterResponsiblePersons.value.length || filterDepartments.value.length)){
              item.visible = filterDevelopers.value.some(developer => item.developers.includes(developer.value)) || filterResponsiblePersons.value.some(person => item.responsiblePersons.includes(person.value)) || filterDepartments.value.some(department => item.tags.includes(department.value));
          }

      });
    };

    watch(() => search.value, (newVal) => {
        filterMenu();
    });

    watch(() => filterDevelopers.value, (newVal) => {
        filterMenu();
    }, {deep: true});

    watch(() => filterResponsiblePersons.value, (newVal) => {
        filterMenu();
    }, {deep: true});

    watch(() => filterDepartments.value, (newVal) => {
        filterMenu();
    }, {deep: true});
</script>

<template>
    <div class="menu-preview">
        <div class="items">
            <div class="top">
                <div class="main-search tw-mb-4 tw-flex tw-items-center tw-flex-col xl:!tw-flex-row ">
                    <div class="xl:tw-w-[50%] tw-w-[100%]">
                        <InputField
                            type="text"
                            class="preview-search"
                            v-model="search"
                            placeholder="Vyhledávání"
                            variant="fancy"
                            icon="search"
                        />
                    </div>
                    <div class="xl:tw-w-[20%] tw-w-[100%] tw-flex tw-gap-10 xl:!tw-pl-10 tw-pl-0 xl:tw-mt-0 tw-mt-2">
                        <div class="tw-flex tw-w-[50%] tw-items-center">
                            Vývojáři:
                            <NewSelect
                                class="tw-ml-4"
                                v-model="filterDevelopers"
                                :filterable="true"
                                :options="developersOptions"
                                :multiple="true"
                                :returnObject="true"
                                size="small"
                            ></NewSelect>
                        </div>

                        <div class="tw-flex tw-w-[40%] tw-items-center">
                            <div>
                                Zod.&nbsp;osoby:
                            </div>
                            <NewSelect
                                class="tw-ml-4"
                                v-model="filterResponsiblePersons"
                                :filterable="true"
                                :options="responsiblePersonOptions"
                                :multiple="true"
                                :returnObject="true"
                                size="small"
                            ></NewSelect>
                        </div>

                        <div class="tw-flex tw-w-[40%] tw-items-center">
                            <div>
                                Oddělení:
                            </div>
                            <NewSelect
                                class="tw-ml-4"
                                v-model="filterDepartments"
                                :filterable="true"
                                :options="departmentsOptions"
                                :multiple="true"
                                :returnObject="true"
                                size="small"
                            ></NewSelect>
                        </div>

                    </div>

                </div>
<!--                <div class="item">-->
<!--                    &lt;!&ndash;                        <a class="icon" n:href="index">&ndash;&gt;-->
<!--                    <a class="icon" href="index">-->
<!--                        <i class="ci ci-home"></i>-->
<!--                    </a>-->
<!--                    <div class="right-part">-->
<!--                        &lt;!&ndash;                            <a class="name menu-item-name" n:href="index" data-description="" data-level="1">{_("Homepage")}</a>&ndash;&gt;-->
<!--                        <a class="name menu-item-name" href="index" data-description="" data-level="1">Homepage</a>-->
<!--                    </div>-->
<!--                </div>-->


                <!--                    {if (isset($path[0]) && $item->getId() == $path[0]->getId()) or (isset($menuitem) && $menuitem->getId() == $item->getId())}active{/if} TODO ACTIVE-->
                <template v-for="(item, index) in menu.menuItems" :key="index">
                    <div
                        class="item"
                        :class="{
                            'active': false
                        }"
                        v-if="item.visible"
                    >
                        <a class="icon" :href="basePath + '/' + item.URL">
                            <i
                                :class="{
                                    ['ci ci-' + item.icon]: true,
                                }"
                            ></i>
                        </a>
                        <div class="right-part">
                            <div class="tw-flex tw-justify-between tw-items-center"
                                 data-toggle="collapse"
                                 :data-target="'#menu' + (index+1000)"
                            >
                                <div>
                                    <div class="tw-flex tw-items-center">
                                        <a
                                            class="name menu-item-name"
                                            :href="basePath + '/' + item.URL"
                                            :data-description="item.description" data-level="1"
                                        >
                                            <HighlightText :highlight="search" :text="item.name" />
                                        </a>
                                        <div class="tags">
                                            <div class="tag" v-for="(tag, index) in item.tags" :key="index">{{ tag }}</div>
                                        </div>
                                    </div>

                                    <div class="description"><HighlightText :highlight="search" :text="item.description" /></div>
                                </div>

                                <div class="tw-flex">
                                    <div class="right-tags">
                                        <div class="developers" v-if="item.developers.length">
                                            Vývojáři:
                                            {{ item.developers.join(', ') }}
                                        </div>
                                        <div class="responsiblePersons" v-if="item.responsiblePersons.length">
                                            Zod. osoby:
                                            {{ item.responsiblePersons.join(', ') }}
                                        </div>
                                    </div>
                                    <div>
                                       <span
                                           v-if="item.menuItems.length"
                                           class="expander"
                                           :class="{
                                            'collapsed': !(search || filterDevelopers.length || filterResponsiblePersons.length || filterDepartments.length)
                                           }"
                                           data-toggle="collapse"
                                           :data-target="'#menu' + (index+1000)"
                                           aria-expanded="false"
                                       >
                                           <span class="plus ci ci-angle-down"></span>
                                       </span>
                                    </div>

                                </div>
                            </div>




                            <template v-if="item.menuItems.length">
                                <div
                                    class="first-submenu collapse tw-mt-2 tw-pl-4"
                                    :id="'menu' + (index+1000)"
                                    :class="{
                                        show: search || filterDevelopers.length || filterResponsiblePersons.length || filterDepartments.length
                                    }"
                                >
                                    <!--                                {if (isset($path[1]) && $second->getId() == $path[1]->getId()) or (isset($menuitem) && $menuitem->getId() == $second->getId())}active{/if} TODO ACTIVE-->
                                    <template v-for="(secondItem, secondIndex) in item.menuItems" :key="secondIndex">
                                        <div
                                            v-if="secondItem.visible"
                                            class="subitem"
                                            :class="{
                                                'active': false
                                            }"
                                        >
                                            <div class="tw-flex tw-justify-between tw-items-center w-100"
                                                 :data-toggle="secondItem.menuItems.length ? 'collapse' : null"
                                                 :data-target="secondItem.menuItems.length ? '#menu' + secondIndex+2000 : null"
                                            >
                                                <div>
                                                    <div class="tw-flex tw-items-center">
                                                        <a
                                                            :href="basePath + '/' + secondItem.URL"
                                                            class="menu-item-name"
                                                            :data-description="secondItem.description"
                                                            data-level="2"
                                                        >
                                                            <HighlightText :highlight="search" :text="secondItem.name" />
                                                        </a>
                                                        <div class="tags">
                                                            <div class="tag" v-for="(tag, index) in secondItem.tags" :key="index">{{ tag }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="description"><HighlightText :highlight="search" :text="secondItem.description" /></div>
                                                </div>
                                                <div class="tw-flex">
                                                    <div class="right-tags">
                                                        <div class="developers" v-if="secondItem.developers.length">
                                                            Vývojáři:
                                                            {{ secondItem.developers.join(', ') }}
                                                        </div>
                                                        <div class="responsiblePersons" v-if="secondItem.responsiblePersons.length">
                                                            Zod. osoby:
                                                            {{ secondItem.responsiblePersons.join(', ') }}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span
                                                            v-if="secondItem.menuItems.length"
                                                            class="expander"
                                                            :class="{
                                                                'collapsed': !(search || filterDevelopers.length || filterResponsiblePersons.length || filterDepartments.length)
                                                            }"
                                                            data-toggle="collapse"
                                                            :data-target="'#menu' + secondIndex+2000"
                                                            aria-expanded="false"
                                                        >
                                                        <span class="plus ci ci-angle-down"></span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <template v-if="secondItem.menuItems.length">
                                                <div
                                                    class="second-submenu collapse tw-mt-2 tw-pl-4"
                                                    :id="'menu' + secondIndex+2000"
                                                    :class="{
                                                        show: search || filterDevelopers.length || filterResponsiblePersons.length || filterDepartments.length
                                                    }"
                                                >
                                                    <template v-for="(thirdItem, thirdIndex) in secondItem.menuItems" :key="thirdIndex">
                                                        <!--                                                        {if (isset($path[2]) && $third->getId() == $path[2]->getId()) or (isset($menuitem) && $menuitem->getId() == $third->getId())}active{/if} TODO ACTIVE-->
                                                        <div
                                                            v-if="thirdItem.visible"
                                                            class="subsubitem"
                                                            :class="{
                                                                'active': false
                                                            }"
                                                        >
                                                            <div class="tw-flex tw-justify-between tw-items-center w-100">
                                                                <div>
                                                                    <div class="tw-flex tw-items-center">
                                                                        <a
                                                                            :href="basePath + '/' + thirdItem.URL"
                                                                            class="menu-item-name"
                                                                            :data-description="thirdItem.description"
                                                                            data-level="3"
                                                                        >
                                                                            <HighlightText :highlight="search" :text="thirdItem.name" />
                                                                        </a>
                                                                        <div class="tags">
                                                                            <div class="tag" v-for="(tag, index) in thirdItem.tags" :key="index">{{ tag }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="description"><HighlightText :highlight="search" :text="thirdItem.description" /></div>
                                                                </div>
                                                                <div class="tw-flex">
                                                                    <div class="right-tags">
                                                                        <div class="developers" v-if="thirdItem.developers.length">
                                                                            Vývojáři:
                                                                            {{ thirdItem.developers.join(', ') }}
                                                                        </div>
                                                                        <div class="responsiblePersons" v-if="thirdItem.responsiblePersons.length">
                                                                            Zod. osoby:
                                                                            {{ thirdItem.responsiblePersons.join(', ') }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped>
.menu-preview {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.menu-preview .items .item,
.menu-preview .items .subitem,
.menu-preview .items .subsubitem {
    display: flex;
    align-items: flex-start;
    padding: 8px;
    margin-bottom: 8px;
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    transition: background-color 0.2s ease;

    .plus{
        font-size: 9px;
        transition: all .3s ease;

        &:hover{
            cursor: pointer;
        }
    }

    .expander:not(.collapsed) .plus {
        transform: rotate(180deg);
    }
}

.menu-preview .items .subitem {
    display: block;
    background-color: #f8f9fa;
}

.menu-preview .items .item:hover,
.menu-preview .items .subsubitem:hover {
    background-color: #f0f4f7;
}

.menu-preview .items .subitem:hover {
    background-color: #ffffff;
}

.menu-preview .items .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    background-color: #e7edf3;
    border-radius: 50%;
    margin-right: 12px;
    font-size: 14px;
    color: #29c489;
}

.menu-preview .items .right-part {
    flex: 1;
}

.menu-preview .items .name {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    text-decoration: none;
    display: block;

    &:hover{
        text-decoration: underline;
    }
}

.menu-preview .items .description {
    font-size: 12px;
    color: #666;
    margin-top: 4px;
    line-height: 16px;
}

.menu-preview .items .tags {
    display: flex;
    gap: 8px;
    margin-left: 9px;
}
.menu-preview .items .menu-item-name{
    &:hover{
        text-decoration: underline;
    }
}


.menu-preview .items .tag {
    padding: 2px 6px;
    font-size: 10px;
    color: #ffffff;
    background-color: #29c489;
    border-radius: 10px;
    text-transform: uppercase;
}

.right-tags{
    display: flex;
    gap: 10px;
    margin-right: 10px;
}

.developers{
    padding: 2px 6px;
    font-size: 12px;
    color: #ffffff;
    background-color: #29c489;
    border-radius: 10px;
}

.responsiblePersons{
    padding: 2px 6px;
    font-size: 12px;
    color: #ffffff;
    background-color: #29c489;
    border-radius: 10px;
}

</style>