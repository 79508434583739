import MenuItem from './MenuItem.js';

export default class Menu
{
    menuItems = [];
    searchVisible = false;
    homepageVisible = false;

    constructor() {

    }

    addItem(id, name, URL, icon = null, description = null, visible = true){
        const item = new MenuItem(id, name, URL, icon, description, visible, this);
        this.menuItems.push(item);

        return item;
    }

    static createInstance(json){
        const menu = new Menu();
        menu.searchVisible = json.searchVisible ?? false;
        menu.homepageVisible = json.homepageVisible ?? false;

        const mapItems = (items, parent) => {
            items.forEach(item => {
                const newItem = parent.addItem(item.id, item.name, item.URL, item.icon, item.description, item.visible); // Přidá aktuální položku
                newItem.badge = item.badge;
                newItem.tags = item.tags;
                newItem.developers = item.developers;
                newItem.responsiblePersons = item.responsiblePersons;

                // Pokud má aktuální položka další menuItems, projde je rekurzivně
                if (item.menuItems && item.menuItems.length > 0) {
                    mapItems(item.menuItems, newItem);
                }
            });
        }

        mapItems(json.menuItems, menu);

        return menu;
    }

}