<script setup lang="ts">

import Modal from "@/Components/Modal/Modal.vue";
import Button from "@/Components/Inputs/Button.vue";
import { ref, watch } from "vue";
import NewSelect from "@/Components/Inputs/NewSelect.vue";
import Checkbox from "@/Components/Inputs/Checkbox.vue";
import {ModelDefinitionJoin} from "@project/Excel/Handsontable/js/ModelDefinitionJoin";

const props = defineProps<{
    state?: {
        company: String|Object,
        joinType: String|Object,
        type: Object,
        deposit: Boolean,
    },
    show: boolean
}>();

const emit = defineEmits(['change', 'close', 'save']);

const state = ref<{
    company: String|Object,
    joinType: String|Object,
    type: String|Object,
    deposit: Boolean
}>(props.state ? {
    ...{
        company: '',
        joinType: ModelDefinitionJoin.JOIN_TYPE_LEFT,
        type: {text: 'Target', value: 'target'},
        deposit: false,
    },
    ...props.state,
} : {
    company: '',
    joinType: ModelDefinitionJoin.JOIN_TYPE_LEFT,
    type: {text: 'Target', value: 'target'},
    deposit: false,
});
const show = ref<boolean>(props.show);

const joinTypeOptions = [
    {text: 'Left', value: ModelDefinitionJoin.JOIN_TYPE_LEFT},
    {text: 'Inner', value: ModelDefinitionJoin.JOIN_TYPE_INNER},
]

const typeOptions = [
    {text: 'Target', value: 'target'},
    {text: 'Prodejní minimální cena', value: 'sale-min'},
    {text: 'Nákupní cena', value: 'purchase'},
    {text: 'Prodejní cena', value: 'sale'},
]

const save = () => {
    emit('save', state.value);
    close();
}

const close = () => {
    show.value = false;
    emit('close', state.value);
}

watch(() => props.show, (val) => {
    show.value = props.show;
});

//url
const url = window.remoteUrl;
//url
</script>

<template>
    <Modal :isVisible="show" @update:isVisible="close" size="extraSmall">
        <template #header>
            Přidat target
        </template>
        <template #body>
            <NewSelect
                v-model="state.company"
                label="Zákazník"
                :url="url+'/admin/price-record-company/data-list?sort=Name&Type=customer'"
                :filterable="true"
                :return-object="true"
            ></NewSelect>
            <NewSelect
                v-if="state.company"
                v-model="state.joinType"
                label="Typ propojení"
                :options="joinTypeOptions"
            ></NewSelect>
            <NewSelect
                v-if="state.company"
                v-model="state.type"
                label="Typ ceny"
                :options="typeOptions"
                :return-object="true"
            ></NewSelect>
            <Checkbox
                v-if="state.company"
                label="Deposit"
                v-model="state.deposit"
                class="tw-mt-2"
            ></Checkbox>
        </template>
        <template #footer>
            <Button variant="green" @click="save">Uložit</Button>
            <Button variant="red" @click="close">Zavřít</Button>
        </template>
    </Modal>
</template>

<style scoped>

</style>