<script setup>
import { ref } from 'vue';
import Icon from './../../Icons/Icon.vue';

const props = defineProps({
    isVisible: {
        type: Boolean,
        default: false
    },
    size: {
        type: String,
        default: 'medium',
        validator: value => ['extraSmall', 'small', 'medium', 'large', 'fullscreen'].includes(value)
    },
    position: {
        type: String,
        default: 'center',
        validator: value => ['right', 'center'].includes(value)
    },
    blockPage: {
        type: Boolean,
        default: true
    },
    fullHeight: {
        type: Boolean,
        default: false,
    }
});

const emit = defineEmits(['update:isVisible']);

const sizeClasses = {
    extraSmall: 'size-extraSmall',
    small: 'size-small',
    medium: 'size-medium',
    large: 'size-large',
    fullscreen: 'size-fullscreen',
};

const positionClasses = {
    right: 'position-right',
    center: 'position-center',
}

const modalSizeClass = ref(sizeClasses[props.size]);
const modalPositionClass = ref(positionClasses[props.position]);

const closeModal = () => {
    emit('update:isVisible', false);
};
</script>

<template>
    <Teleport to="body">
        <div class="tailwind">
            <div v-if="isVisible" tabindex="-1" aria-hidden="true" class="modal" :class="[{ 'no-block': !blockPage, 'full-height': fullHeight }, modalSizeClass, modalPositionClass]">
                <div class="modal-wrapper">
                    <div class="content-wrapper">
                        <!-- Modal header -->
                        <div class="header">
                            <h3>
                                <slot name="header">
                                    Default header content
                                </slot>
                            </h3>
                            <div class="close-icon-holder">
                                <Icon class="icon" icon="close" @click="closeModal" color="gray" :width="15" :height="15"/>
                            </div>
                        </div>
                        <!-- Modal body -->
                        <div class="body">
                            <slot name="body">
                                <p class="default-content">
                                    Default body content.
                                </p>
                            </slot>
                        </div>
                        <!-- Modal footer -->
                        <div class="footer">
                            <slot name="footer">
                                <!--                                <button @click="closeModal" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Default action</button>-->
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<style scoped>
.modal {
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-height: 100%;
    background-color: rgba(31, 41, 55, 0.40); /* bg-gray-900/50 */

    &.no-block {
        background-color: transparent;
        margin-top: auto;
        margin-bottom: auto;

        &:not(.full-height) {
            height: fit-content;
        }

        &.size-extraSmall {
            max-width: 36rem;
        }

        &.size-small {
            max-width: 48rem;
        }

        &.size-medium {
            max-width: 72rem;
        }

        &.size-large {
            max-width: 1500px;
        }

        &.size-fullscreen {
            max-width: 100%;
        }

        &.position-right {
            margin-left: auto;
        }

        &.position-center {
            margin-left: auto;
            margin-right: auto;
        }

        .modal-wrapper {
            .content-wrapper {
                box-shadow: 0 0 4px 4px rgba(9, 19, 37, 0.10);
            }
        }
    }

    &:not(.no-block) {
        &.position-right {
            .modal-wrapper {
                margin-left: auto;
            }
        }

        &.position-center {
            .modal-wrapper {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &.size-extraSmall {
        .modal-wrapper {
            max-width: 36rem;
        }
    }

    &.size-small {
        .modal-wrapper {
            max-width: 48rem;
        }
    }

    &.size-medium {
        .modal-wrapper {
            max-width: 72rem;
        }
    }

    &.size-large {
        .modal-wrapper {
            max-width: 1500px;
        }
    }

    &.size-fullscreen {
        .modal-wrapper {
            max-width: 100%;
        }
    }

    &.full-height {
        .modal-wrapper {
            height: 100%;

            .content-wrapper {
                height: 100%;

                display: flex;
                flex-direction: column;

                .body {
                    flex-grow: 1;
                }
            }
        }
    }
}

.modal-wrapper {
    position: relative;
    padding: 16px;
    width: 100%;
    max-height: 100vh;
    z-index: 100;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .content-wrapper {
        position: relative;
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 4px 44px 0px rgba(9, 19, 37, 0.10);
        max-height: 100%; /* Makes content wrapper respect the parent's max-height */
        overflow-y: auto;

        .header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 28px 28px 13px 28px;

            h3{
                font-size: 16px;
                font-weight: 600;
                margin: 0;
                width: calc(100% - 20px);
            }

            .close-icon-holder{
                position: relative;
                width: 20px;
                height: 20px;

                .icon{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateY(-50%) translateX(-50%);

                    &:hover{
                        cursor: pointer;
                        filter: drop-shadow(0px 1px 1px rgb(0 0 0 / 0.36));
                        opacity: 0.8;
                    }
                }
            }
        }

        .body{
            padding: 13px 28px 13px 28px;
            overflow: auto;

            .default-content{
                font-size: 16px;
                line-height: 1.625;
                color: #6b7280;
            }
        }

        .footer {
            display: flex;
            align-items: center;
            padding: 13px 28px 28px 28px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
}

html.prefer-dark {
    .modal-wrapper {
        .content-wrapper {
            background: #333;
            box-shadow: none;
        }
    }
}

/* Media query for md:inset-0 */
@media (min-width: 768px) {
    .modal {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
</style>
